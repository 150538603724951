import {
  ApprovalTarget,
  ApprovalStatus,
  INgReasons,
  TrainservicemanOperation
} from '@sagano/admin/services/const';

export default {
  common: {
    action: '操作',
    sagano: '嵯峨野',
    number: '号',
    amount: '金額',
    updated: '{{name}}を更新しました。',
    confirmSave: '更新ボタンを押すことでデータが保存されます。',
    emptyContent: '入力内容がありません。',
    invalidInputContent: '入力内容に不備があります。'
  },
  lang: {
    en: '英語',
    ja: '日本語',
    zh: '中国語(簡体)',
    zt: '中国語(繁体)',
    ko: '韓国語'
  },
  layouts: {
    sidebar: {
      accountManager: 'アカウント管理',
      agent: '代理店一覧',
      masterManager: 'マスタ管理',
      serviceManager: '運用管理',
      groupManager: '団体管理',
      groupSalesManager: '団体売上管理'
    }
  },
  views: {
    booking: {
      title: '団体予約一覧/検索',
      editAmount: '金額を編集',
      backToAutoCalc: '自動計算に戻す',
      confirmToDo: '「{{action}}」を確定します',
      assignSeatsOK: '「席付OK」を確定します',
      recordNote: '特記事項があれば記入してください',
      sendVoucherNoteTitle: '席番表送付',
      assignOperatorSuccess: '担当者を登録しました。',
      reason: '理由',
      ngTips: `（確保済席数は解放されます）
      予約受付不可の理由を選択してください`,
      cancelPaymentFinishedTitle: '支払ステータスを変更します。',
      cancelPaymentFinishedTip:
        '変更したステータスは元に戻せません。よろしいですか？'
    },
    common: {
      unitAnnotationId: {
        NORMAL: '一般',
        HANDICAPPED_1: '障害者1種',
        HANDICAPPED_2: '障害者2種',
        SUPPORTER: '介助者',
        FREE: '無賃'
      },
      fareTypeId: {
        ADULT: '大人',
        CHILD: '小児'
      },
      userGroup: {
        UNDEFINED: '未定義',
        BACK_OFFICE: 'バックオフィス',
        STATION: '駅スタッフ',
        TRAIN: '列車スタッフ'
      },
      userType: {
        ANONYMOUS: '匿名',
        ADMIN: '管理者',
        STAFF: '担当者'
      },
      ngReasons: {
        [INgReasons.Full]: '満席',
        [INgReasons.ServiceSuspension]: '終日運休',
        [INgReasons.ServicedaySuspension]: '希望列車運休',
        [INgReasons.Other]: 'その他'
      }
    },
    dashboard: {
      message: {
        type: '向け',
        addSuccess: 'コンテンツを追加しました。',
        updateSuccess: 'コンテンツを変更しました。',
        deleteSuccess: 'コンテンツを削除しました。'
      }
    },
    serviceDate: {
      title: '適用日',
      add: '適用を開始する日付を選択してください',
      addSuccess: '適用日を追加しました。'
    },
    users: {
      title: '嵯峨野ユーザー管理',
      email: 'メールアドレス(ログインID)',
      tel: '電話番号',
      name: '担当者名',
      languageCode: '言語',
      userGroup: 'ユーザーグループ',
      userType: 'ユーザータイプ',
      sendPasswordResetEmail: 'パスワードリセットメール送信',
      sendPasswordResetEmailSuccess:
        'パスワードリセットメールを{{email}}に送信しました。',
      addSuccess: 'ユーザーを追加しました。',
      updateSuccess: 'ユーザーを更新しました。',
      deleteSuccess: 'ユーザーを削除しました。'
    },
    basicInfo: {
      title: '事業者情報',
      updateSuccess: '事業者情報を更新しました。',
      form: {
        operatorId: '事業者ID',
        name: '事業者名称',
        shortName: '事業者名略称',
        tel: '電話番号',
        telEmergency: '電話番号(緊急)',
        bizTime: '営業時間',
        address: '住所',
        addressPostalCode: '住所郵便番号',
        managementRepresentative: '管理代表者名',
        managementRepresentativeDepartment: '管理代表者所属',
        invoiceNumber: '適格請求書発行事業者登録番号',
        logoUrl: '会社ロゴUrl',
        logo: '会社ロゴ',
        stampUrl: '社印イメージUrl',
        stamp: '社印イメージ'
      }
    },
    bankAccounts: {
      title: '銀行口座一覧',
      addTitle: '銀行口座登録',
      editTitle: '銀行口座編集',
      companyName: '会社名',
      bankName: '金融機関名',
      branchName: '支店名',
      branchCode: '支店番号',
      accountCode: '口座番号',
      accountName: '口座名義',
      id: 'ID',
      accountType: '預金種別',
      bankAccountType: {
        ACCOUNT_TYPE_ORDINARY: '普通',
        ACCOUNT_TYPE_SAVING: '貯蓄',
        ACCOUNT_TYPE_CHECKING: '当座'
      },
      addSuccess: '銀行口座を追加しました。',
      editSuccess: '銀行口座を更新しました。'
    },
    approvals: {
      title: '申請一覧',
      table: {
        id: '申請ID',
        status: 'ステータス',
        submitInfo: '申請人情報',
        judgeInfo: '審査者情報',
        target: '変更内容'
      },
      status: {
        [ApprovalStatus.JobPending]: '処理中',
        [ApprovalStatus.JobFailed]: '処理失敗',
        [ApprovalStatus.Rejected]: '拒否',
        [ApprovalStatus.Submitted]: '申請中',
        [ApprovalStatus.Done]: '完了'
      },
      target: {
        [ApprovalTarget.Unspecified]: '未指定',
        [ApprovalTarget.ServiceTermInserting]: '運行期間追加',
        [ApprovalTarget.ServiceDayPatternUpserting]: '運行パターン変更',
        [ApprovalTarget.ArrangementApplicationTermInserting]:
          '席パターン適用期間追加',
        [ApprovalTarget.ArrangementApplicationTermApplicationAppending]:
          '席パターン適用運行追加',
        [ApprovalTarget.ServiceDayServiceAppendings]: '運行日へ運行列車を追加'
      },
      operation: {
        title: '申請一覧',
        applicant: '申請者',
        approved: '承認',
        rejected: '拒否',
        reason: '理由',
        description: '申請内容'
      },
      approve: '申請が承認されました',
      reject: '申請が拒否されました'
    },
    groupSchedules: {
      title: '団体スケジュール',
      serviceDate: '乗車期間',
      name: '名称',
      releaseDay: '団体枠解放日（日前）',
      paymentDay: '請求日（日前）',
      remindDay: '督促日（日前）',
      updateSuccess: '団体スケジュールを更新しました。',
      normalSchedule: '通常運行期間',
      irregularSchedule: 'イレギュラー運行期間'
    },
    freeCountPlans: {
      title: '団体無賃人数設定',
      addSuccess: '団体無賃適用日を追加しました。',
      freeCondition: '無賃人数条件',
      freeCount: '無賃人数',
      updateSuccess: '団体無賃人数設定を更新しました。',
      addFreePlan: '団体無賃扱い追加',
      deleteSuccess: '団体無賃適用日を削除しました。',
      errorNumber: '正しい人数を入力してください。'
    },
    specialContractTerms: {
      title: '特約期間設定',
      addTerm: '特約期間新規追加',
      addSuccess: '特約期間を追加しました。',
      editTerm: '特約期間編集',
      editSuccess: '特約期間を更新しました。',
      deleteSuccess: '特約期間を削除しました。',
      name: '特約期間名称',
      submissionDeadline: '受付締切日',
      answerDate: '回答日',
      serviceDate: '乗車期間',
      serviceDateFrom: '乗車期間(開始)',
      serviceDateTo: '乗車期間(終了)',
      invalidServiceDateTo: '乗車期間(終了)は乗車期間(開始)の後にしてください'
    },
    services: {
      title: '列車/運行時刻',
      term: {
        title: '運行期間',
        add: '運行期間新規追加',
        fromDate: '運行開始日',
        toDate: '運行終了日',
        addSuccess: '運行期間追加を申請しました。'
      },
      trainInfo: {
        addTitle: '列車新規追加',
        editTitle: '列車時刻を編集',
        train: '列車名',
        direction: '方向',
        isSpecial: '定期便外設定',
        timeTitle: '発着時刻を入力してください。',
        inputAllTime: '全ての発着時刻を入力してください。',
        arrive: '着',
        depart: '発',
        updateSuccess: '運行情報を更新しました。',
        addSuccess: '運行情報を追加しました。',
        deleteSuccess: '運行情報を削除しました。'
      },
      trainTable: {
        down: '【下り】トロッコ嵯峨→トロッコ亀岡',
        up: '【上り】トロッコ亀岡→トロッコ嵯峨',
        trainName: '列車名',
        isSpecial: '定期便外',
        timeLine: '時刻表'
      }
    },
    patternCalendar: {
      title: '運行スケジュール設定',
      servicePattern: {
        ORDINARY: '通常運行',
        SPECIAL: '定期便外',
        SUSPENDED: '運休日',
        DISABLED: '運行適用期間外'
      },
      edit: '編集',
      updated: '更新',
      updateSuccess: 'スケジュール変更申請しました。'
    },
    specialDays: {
      title: '定期便外運行日設定',
      allSelect: '全て選択',
      updateSuccess: '変更申請が成功しました',
      noChange: '変更がありません'
    },
    groupPricingPlans: {
      title: '団体料金設定',
      normalPrice: '一般団体料金',
      schoolPrice: '学生団体料金',
      serviceDate: '乗車期間',
      name: '名称',
      fare: '運賃',
      price: '料金',
      decrementFee: '減員手数料',
      updateSuccess: '団体料金設定を更新しました。',
      invalidName: '名称を入力してください。'
    },
    arrangementApplication: {
      title: '特定枠設定',
      addTitle: '特定枠設定：追加',
      addSuccess: '特定枠設定を追加申請しました。',
      editTitle: '特定枠設定：編集',
      editSuccess: '特定枠設定を更新申請しました。',
      disableEdit: '申請中 編集不可',
      term: {
        title: '適用期間',
        down: '下り トロッコ亀岡行',
        up: '上り トロッコ嵯峨行'
      }
    },
    arrangementPattern: {
      title: '枠パターン',
      addTitle: '枠パターン：追加',
      addSuccess: '枠パターンを追加しました。',
      editTitle: '枠パターン：編集',
      updateSuccess: '枠パターンを更新しました。',
      deleteSuccess: '枠パターンを削除しました。',
      name: '枠パターン名',
      carName: '車両',
      invalidArrangmentType: 'すべての席に枠パターンを選択してください。',
      autoSettings: {
        title: '自動設定',
        carNo: '号車',
        seatNo: '席',
        from: 'から',
        to: 'まで',
        switchTo: 'へ切替',
        total: '合計',
        setting: '設定'
      }
    },
    inventoryArrangements: {
      title: '枠調整',
      vacantNumber: '空席数',
      reserved: '予約済',
      updateSuccess: '枠調整を更新しました。',
      tips: '手動設定をする場合、上部空席数一覧から切替先の席種（色）を選択した後に席を選択してください。選択した席は切替先の色に変わります'
    },
    assignSeats: {
      title: '席付（{{direction}}）',
      tips: '手動設定をする場合、席を選択してください。「団体席」から選択可能です。現在確保中の席には<0></0>がつきます。',
      executeAssign: '席付実行',
      releaseAssign: '解放実行',
      downSeatsAssign: '往路を席付',
      upSeatsAssign: '復路を席付',
      seatsAssignOk: '席付OK',
      seatsAssignNg: '席付NG',
      assignedSeats: '席付数',
      specifiedSeats: '指定数'
    },
    ensureSeats: {
      seatsNoGuarantee: '席数確保NG',
      seatsSheet: '席番表',
      seatStatus: '席数／席確保状況',
      serviceOrder: '乗車希望',
      seatReservationActionTitle: 'ステータス変更（席数確保）',
      seatAllotmentActionTitle: 'ステータス変更（席付）',
      sendEmail: '送信する',
      sendEmailTips: '送信は一度実行すると取り消せません。送信しますか？',
      reference: '参照',
      sendSheet: '送付',
      currentDayRelease: '当日開放',
      currentDayReleaseNoteTitle: '席を開放しますか？',
      releaseSuccess: '席を開放しました'
    },
    report: {
      title: '帳票データ出力',
      type: 'データ出力',
      date: '期間',
      downloadSuccess: '帳票データ出力が完了しました。',
      reportTypes: {
        booking: '団体予約・列車データ',
        service: '列車データ（共通）',
        agent: '代理店',
        prepaid: 'クレカ精算データ',
        sales: '売上情報'
      }
    },
    suspended: {
      title: '運休処理',
      operation: {
        [TrainservicemanOperation.Suspended]: '運休',
        [TrainservicemanOperation.Pending]: '発売保留',
        [TrainservicemanOperation.RemovePending]: '保留解除'
      },
      bulkOperate: '一括選択/解除',
      suspendedDialogTitle: '本当に運休しますか？',
      suspendedDialogContent: '運休は取り消せません。',
      pendingDialogTitle: '保留します。よろしいですか？'
    },
    operationLogs: {
      title: '操作履歴',
      date: '期間',
      operator: '操作者',
      category: 'カテゴリ',
      operatorTime: '操作日時',
      operation: '操作内容',
      additionalInfos: '付帯情報'
    },
    OperationCategory: {
      COMMON: '共通',
      GROUP: '団体',
      MASTER: 'マスタ',
      STATION: '駅'
    },
    sales: {
      title: '売上管理'
    },
    login: {
      title: 'Welcome to Sagano Admin'
    },
    bulkOperation: {
      title: '特約一斉回答',
      optionSelect: '登録内容',
      option: {
        reserveAllotments: '自動席数確保',
        confirmBooking: '一斉回答'
      },
      fileSelect: 'アップロードファイル',
      fileSelectPlaceholder: '選択してください',
      submitCheck: '{{type}}を実行します。\nよろしいですか？',
      response: '{{type}}を実行しました。',
      responseText:
        '処理結果はメールで送付します。\n受信後に内容を確認してください。'
    }
  }
};
