import { message } from '@linktivity/link-ui';
import { AdminMixer } from '@linktivity/sagano';
import { auth } from '@sagano/admin/stores';

const BASE_PATH = import.meta.env.VITE_API_ADMIN_HOST;

const config = new AdminMixer.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json'
        });
        requestHeaders.append(
          'grpc-metadata-auth-token',
          await auth.getRefreshedToken()
        );
        context.init.headers = requestHeaders;
      },
      post: async context => {
        if (!context.response.ok) {
          // handle error
          const data = await context.response.json();
          message.error(
            data?.common?.error_description ||
              data?.common?.error_code ||
              context.response.status,
            10
          );
        }
      }
    }
  ]
});

export default config;
